import { Button, ContentCard, Row } from "@narmi/design_system";
// @ts-ignore
import React, { useEffect, useRef } from "react";

const ProductCard = ({
  product,
  onClick,
  isSelected,
  hideRecommendedTag = false,
  monthlyServiceFeeText = "Monthly service fee",
  recommendedLabel = "Recommended",
  benefitsLabel = "Benefits",
  moreDetailsLabel = "More details",
}: {
  product: any;
  onClick: CallableFunction;
  isSelected: boolean;
  monthlyServiceFeeText: String;
  hideRecommendedTag?: boolean;
  recommendedLabel?: String;
  benefitsLabel?: String;
  moreDetailsLabel?: String;
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleLinkClick = (event: MouseEvent) => {
      event.stopPropagation();
    };

    if (contentRef.current) {
      const links = contentRef.current.querySelectorAll("a");
      links.forEach((link) => {
        link.addEventListener("click", handleLinkClick);
      });
    }

    return () => {
      if (contentRef.current) {
        const links = contentRef.current.querySelectorAll("a");
        links.forEach((link) => {
          link.removeEventListener("click", handleLinkClick);
        });
      }
    };
  }, [product]);

  const convertMarkdownLinksToHTML = (markdown: string) =>
    markdown.replace(/\[([^\]]+)]\(([^)]+)\)/g, '<a href="$2" target="_blank">$1</a>');

  return (
    <ContentCard
      kind="interactive"
      isSelected={isSelected}
      onClick={() => {
        onClick();
      }}
      radiusSize="m"
    >
      <div className="product-card">
        {product.recommended && !hideRecommendedTag && (
          <div className="tag">{recommendedLabel}</div>
        )}
        <div className="product-header">
          <Row alignItems="center">
            <Row.Item>
              <div
                className="fontWeight--semibold fontSize--l"
                style={{ color: "RGB(var(--nds-black))" }}
              >
                {product.name}
              </div>
            </Row.Item>
            {isSelected && (
              <Row.Item shrink>
                <div className="circle">
                  <span className="narmi-icon-check" />
                </div>
              </Row.Item>
            )}
          </Row>
          <div className="product-description">{product.description}</div>
        </div>
        <div className="product-card-body">
          <h5>{benefitsLabel}</h5>
          <ul>
            {product.details
              ?.filter((detail: any) => detail.trim() !== "")
              .map((detail: any) => (
                <li key={detail}>
                  <div
                    className="markdown-content"
                    ref={contentRef}
                    dangerouslySetInnerHTML={{
                      __html: convertMarkdownLinksToHTML(detail),
                    }}
                  />
                </li>
              ))}
          </ul>
        </div>
        <div className="product-card-footer">
          <h5>{monthlyServiceFeeText}</h5>
          <div>{product.monthly_service_fee || "$0"}</div>
          {product.more_details_url && (
            <div className="margin--top--l margin--bottom--s">
              <Button kind="plain" size="s">
                <a
                  href={`${product.more_details_url}`}
                  target="blank"
                  rel="noreferrer"
                  onClick={(event) => event.stopPropagation()}
                >
                  {product.more_details_copy || moreDetailsLabel} &gt;
                </a>
              </Button>
            </div>
          )}
        </div>
      </div>
    </ContentCard>
  );
};

export default ProductCard;
